// script.js

document.addEventListener('DOMContentLoaded', function() {
    const navbar = document.querySelector('nav.navbar');
    const navLinks = document.querySelectorAll('.nav-links a');
    const sections = document.querySelectorAll('section');
    const hamburger = document.querySelector('.hamburger');
    const navLinksContainer = document.querySelector('.nav-links');

    // Toggle hamburger menu
    hamburger.addEventListener('click', () => {
        navLinksContainer.classList.toggle('show');
    });

    window.addEventListener('scroll', function() {
        // Toggle solid class based on scroll position
        if (window.scrollY > navbar.offsetHeight) {
            navbar.classList.add('solid');
        } else {
            navbar.classList.remove('solid');
        }

        // Calculate the current scroll position
        let scrollPosition = window.scrollY + navbar.offsetHeight;

        // Update active link based on scroll position
        let currentSection = '';
        sections.forEach((section, index) => {
            const sectionTop = section.offsetTop - navbar.offsetHeight;
            const sectionBottom = sectionTop + section.offsetHeight;

            // Check if scroll position is within the current section
            if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                currentSection = section.getAttribute('id');
            }

            // Handle the last section separately to ensure it's correctly detected
            if (index === sections.length - 1 && window.scrollY + window.innerHeight >= document.body.offsetHeight) {
                currentSection = section.getAttribute('id');
            }
        });

        // Update the active class for navigation links
        navLinks.forEach(link => {
            link.classList.remove('active');
            if (link.getAttribute('href').includes(currentSection)) {
                link.classList.add('active');
            }
        });
    });

    const langLinks = document.querySelectorAll('.language-switcher .lang-link');
    const elementsToTranslate = document.querySelectorAll('[data-lang-en]');

    // Default language set to German
    let currentLanguage = 'de';

    langLinks.forEach(link => {
        link.addEventListener('click', function(e) {
            e.preventDefault();

            currentLanguage = this.dataset.lang;
            toggleLanguage(currentLanguage);

            langLinks.forEach(link => link.classList.remove('active'));
            this.classList.add('active');
        });
    });

    function toggleLanguage(lang) {
        elementsToTranslate.forEach(el => {
            if (lang === 'de') {
                el.innerHTML  = el.dataset.langDe;
                document.documentElement.lang = "de";
            } else {
                el.innerHTML  = el.dataset.langEn;
                document.documentElement.lang = "en";
            }
        });
    }

    // Set default language on page load
    toggleLanguage(currentLanguage);
});
